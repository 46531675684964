import React, { useMemo } from "react"
import { Modal } from "react-bootstrap"
import Loader from "react-loader-spinner"
import { Map } from "google-maps-react"
import SignUpPopOver from "./SignUp"
import { ProductionBarChart } from "./Plots"
import { DataBand, DataCol } from "./OutputFields"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { PolygonUnits } from "./MapPanel"

export function ResultModal(props) {
  const polylist = useMemo(
    () => props.areas.map((coords, i) => <PolygonUnits key={i} coords={coords} config={props.config} />),
    [props.config, props.areas]
  )

  return (
    <>
      <Modal show={props.pageShowing} onHide={props.pageToggle} dialogClassName="modals">
        {props.analysisReady ? (
          <Modal.Body>
            <Map
              google={props.google}
              style={{ overflow: "visible", position: "relative", width: "100%", height: "100%" }}
              className={"gmap"}
              visible={true}
              mapTypeControl={false}
              streetViewControl={false}
              fullscreenControl={false}
              zoomControl={false}
              mapType={"hybrid"}
              scaleControl={true}
              initialCenter={props.mapCenter}
              zoom={props.zoom}
              draggable={false}
            >
              {polylist}
            </Map>
            <DataBand area={props.area} pvOutputData={props.pvOutputData} priceEstimate={props.priceEstimate} />
            <div className="row">
              <div className="leftcolumn">
                <DataCol pvOutputData={props.pvOutputData} area={props.area} />
              </div>
              <div className="rightcolumn">
                <ProductionBarChart className="chart" data={props.pvOutputData} />
              </div>
            </div>
            <div className="row">
              <SignUpPopOver
                handleClose={props.pageToggle}
                areas={props.areas}
                pvOut={props.pvOutputData ? props.pvOutputData["peak_power"] : -1}
              />
            </div>
          </Modal.Body>
        ) : (
          <>
            <div style={{ textAlign: "center", margin: "auto", width: "50%" }}>
              <Loader
                type="Grid"
                color="#578dc8"
                secondaryColor="#ECBE43"
                height={80}
                width={80}
                style={{ margin: "8%" }}
              />

              <p style={{ marginBottom: "5%" }}> Crunching the data for you, it will be just a few seconds ...</p>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
